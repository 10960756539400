<template>
  <div class="banner">
    <img
      :src="bannerImage"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      bannerImage: require("@/assets/pc/bg_pc.jpg"),
    };
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
}
.banner > img {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  display: block;
  border: 0;
}
</style>