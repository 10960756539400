<template>
  <!-- <div class="body">
    <div class="wrap">
      <Banner />
      <div class="content">
       
      </div>
      <div class="footer"></div>
    </div>
  </div> -->

  <div>
    <!-- <el-link class="backHome" type="primary" @click="backHome"
      >返回首页</el-link
    > -->
    <el-tabs v-model="currentTabName" @tab-click="tabClick">
      <el-tab-pane label="07-23" name="2021-07-23"></el-tab-pane>
      <el-tab-pane label="07-24" name="2021-07-24"></el-tab-pane>
      <el-tab-pane label="07-25" name="2021-07-25"></el-tab-pane>
    </el-tabs>

    <el-table
      :data="tableData"
      :show-header="false"
      :highlight-current-row="false"
      style="width: 100%"
    >
      <el-table-column fixed label="组" width="70">
        <template slot-scope="scope">
          <div class="GroupName">{{ scope.row.GroupName }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="meetingBox">
            <div class="meetingContent">
              <van-button
                class="customButton"
                type="primary"
                v-for="t in scope.row.Schedules"
                :key="t.ID"
                @click="scheduleDetail(t)"
              >
                <div>
                  <div class="date">{{ t.Time }}</div>
                  <div class="desc">
                    {{ t.Content | formatContent }}
                  </div>
                </div>
              </van-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Banner from "@/components/banner";
import { GetSchedule } from "@/api/index.js";

export default {
  name: "PCSchedule",
  data() {
    return {
      tableData: [],
      currentTabName: "2021-07-23",
    };
  },
  methods: {
    async GetScheduleAsync() {
      const r = await GetSchedule({
        date: this.currentTabName,
      });
      if (r.code === 1) {
        // console.log(r.data);
        this.tableData = r.data;
      } else {
      }
    },
    scheduleDetail(t) {
      // console.log(t);
      this.$router.push({
        name: "PCScheduleDetail",
        params: {
          scheduleId: t.ID,
        },
      });
    },
    tabClick(tab) {
      // console.log(tab.name);
      this.currentTabName = tab.name;
      this.GetScheduleAsync();
    },
    backHome() {
      this.$router.push("/pc/home");
    },
  },
  created() {
    this.GetScheduleAsync();
  },
  filters: {
    formatContent(v) {
      return v || "空";
    },
  },
  components: {
    Banner,
  },
};
</script>

<style scoped>
.body {
  /* background-color: rgb(229, 241, 253); */
}
.wrap {
  min-width: 1200px;
  min-height: 100%;
  background-size: 100% auto;
  background-color: #e5e5e5;
}

.content {
  padding-top: 20px;
}
h4 {
  margin: 0;
}
.content > div {
  width: 1159px;
  min-height: 600px;
  margin: 0 auto;
  position: relative;
}

.backHome {
  position: absolute;
  right: 20px;
  top: 6px;
  z-index: 200;
}

.el-tabs >>> .el-tabs__item:hover {
  color: #c10000;
}
.el-tabs >>> .el-tabs__item.is-active {
  color: #c10000;
}
.el-tabs >>> .el-tabs__active-bar {
  background-color: #c10000;
}

.el-table >>> td,
.el-table >>> th {
  padding: 5px 0;
}

.el-table >>> .el-table_1_column_1 .cell {
  background-color: #f3ead8;
  color: #be7518;
  height: 100px;
  display: flex;
  align-items: center;
}
.el-table >>> td {
  border-bottom: none;
}
.el-table >>> .hover-row > td {
  background-color: #fff;
}
.meetingBox {
  width: 100%;
  overflow-x: auto;
}
.meetingContent {
  white-space: nowrap;
}
.van-button {
  margin-right: 10px;
}
.customButton {
  width: 140px;
  height: 100px;
  padding: 0;
  background: #f3ead8;
  border: 1px solid #fff;
}
.customButton >>> .van-button__text {
  display: block;
  width: 100%;
  height: 100%;
}
.customButton .date {
  background: #e3b965;
  color: #fff;
  font-size: 12px;
  padding: 5px;
}
.customButton .desc {
  white-space: normal;
  background-color: transparent;
  color: #444444;
  /* border-radius: 5px; */
  padding: 5px;
  text-align: left;
  font-size: 12px;
  min-height: 74px;
  line-height: 1.5;
}
</style>